import { getField, updateField } from 'vuex-map-fields'
import { getFormInitState, GlobalFormStore } from '~/mixins/globalForm'
import { prepareUrl, uri } from '~/api/uri'

export const customerFormFields = {
  display_name: { type: String },
  control_nickname: { type: String },
  status: { type: String },
  platform: { type: String },
  // Options
  privacy: { type: Number },
  level: { type: Number },
  doc_verified: { type: Boolean },
  is_vip: { type: Boolean },
  gallery_verified: { type: Boolean },
  gallery_verified_by_admin: { type: Boolean },
  city_changed_by_admin: { type: Boolean },
  ranking: { type: Number },
  stars: { type: Number },
  stars_fixed: { type: Boolean },
  stars_changed: { type: Boolean },
  ranking_blocked_value: { type: Number },
  real_ranking: { type: Number },
  disable_purchases: { type: Boolean },
  isSilenced: { type: Boolean },
  can_invite: { type: Boolean },
  whitelisted: { type: Boolean },
  alert_media: { type: Boolean },
  block_chat_credits: { type: Boolean },
  check_task_enabled: { type: Boolean },
  check_task_duration: { type: Number },
  check_task_created_at: { type: String },
  block_chat_new: { type: Boolean },
  block_feedback: { type: Boolean },
  // Info
  email: { type: String },
  mobile: { type: String },
  telegram: { type: String },
  password: { type: String },
  sms: { type: Boolean },
  email_notification: { type: Boolean },
  city_id: { type: Number },
  bday: { type: String },
  characterMsgCost: { type: Number },
  prime_duration: { type: Number },
  characterStopLoss: { type: Number },
  characterCostModifier: { type: Number },
  characterHostessNationality: { type: Uint32Array },
  characterSpokenLanguages: { type: Uint32Array },
  characterHeight: { type: Number },
  characterSize: { type: Number },
  characterShoesNumber: { type: Number },
  characterServices: { type: Uint32Array },
  characterAvailableForTransfer: { type: Boolean },
  characterFreeChat: { type: Boolean },
  characterWing: { type: Boolean },
  characterDisableCostModifier: { type: Boolean },
  hw_friend: { type: Boolean },
  // Description
  description: { type: String },
  // Wallet
  bonusCredits: { type: Number },
  cashout: { type: Number },
  addCredits: { type: Number },
  addBoostPackageId: { type: Number }
}

const storageKey = 'CustomerEditForm'

const { gfActions, gfMutations, gfGetters } = GlobalFormStore(storageKey, customerFormFields)

export const customerEditInitState = {
  forms: []
}
export const state = () => {
  return {
    ...customerEditInitState
  }
}
export const actions = {
  ...gfActions,
  async addNote ({ commit }, { itemId, type, note }) {
    const url = prepareUrl(uri.customers.noteAdd, { userId: itemId })
    const response = await this.$axios.post(url, { type, note })
    const noteObj = response && response.data
    commit('addNote', { itemId, type, note: noteObj })
  },
  async deleteNote ({ commit }, { itemId, type, noteId }) {
    const url = prepareUrl(uri.customers.noteDelete, { userId: itemId })
    const response = await this.$axios.post(url, { type, noteId })
    if (response && response.status === 204) {
      commit('deleteNote', { itemId, type, noteId })
    }
  },
  async lockPassword ({ commit }, itemId) {
    const url = prepareUrl(uri.customers.lockPassword, { userId: itemId })
    const response = await this.$axios.post(url, {})
    if (response && response.data && response.data.status === 'ok') {
      commit('lockPassword', { itemId, data: response.data.data })
    }
  },
  async unlockPassword ({ commit }, itemId) {
    const url = prepareUrl(uri.customers.unlockPassword, { userId: itemId })
    const response = await this.$axios.post(url, {})
    if (response && response.data && response.data.status === 'ok') {
      commit('unlockPassword', { itemId })
    }
  },
  async permanentDeleteList ({ commit }, itemId) {
    const url = prepareUrl(uri.customers.permanentDeleteList, { userId: itemId })
    const response = await this.$axios.post(url, {})
    if (response && response.data && response.status === 200) {
      commit('setPermanentDeleteList', { itemId, data: response.data })
    }
  },
  async permanentDelete ({ commit }, itemId) {
    const url = prepareUrl(uri.customers.permanentDelete, { userId: itemId })
    const response = await this.$axios.delete(url, {})
    if (response && response.data && response.status === 200) {
      commit('setPermanentDeleteList', { itemId, data: response.data })
    }
  },
  async verifyEmail ({ commit }, { itemId, value }) {
    const url = prepareUrl(uri.customers.verifyEmail, { userId: itemId })
    await this.$axios.post(url, { id: itemId, email: value })
  },
  async unverifyEmail ({ commit }, { itemId, value }) {
    const url = prepareUrl(uri.customers.unverifyEmail, { userId: itemId })
    await this.$axios.post(url, { id: itemId, email: value })
  },
  async verifyMobile ({ commit }, itemId) {
    const url = prepareUrl(uri.customers.verifyMobile, { userId: itemId })
    await this.$axios.post(url, { id: itemId })
  },
  async unverifyMobile ({ commit }, itemId) {
    const url = prepareUrl(uri.customers.unverifyMobile, { userId: itemId })
    await this.$axios.post(url, { id: itemId })
  },
  async chartCredits ({ commit }, { itemId, dateRange, displayRange }) {
    const url = prepareUrl(uri.customers.chartCredits, { userId: itemId })
    const response = await this.$axios.post(url, {
      dateRange,
      displayRange
    })
    if (response && response.data && response.status === 200) {
      commit('setChartCredits', { itemId, data: response.data })
    }
  },
  approveFreeLikeMessage (_, { itemId }) {
    const url = prepareUrl(uri.customers.approveFreeLikeMessage, { userId: itemId })
    return this.$axios.post(url, {})
  },
  declineFreeLikeMessage (_, { itemId }) {
    const url = prepareUrl(uri.customers.declineFreeLikeMessage, { userId: itemId })
    return this.$axios.post(url, {})
  },
  updateFreeLikeMessage (_, { itemId, message }) {
    const url = prepareUrl(uri.customers.updateFreeLikeMessage, { userId: itemId, message })
    return this.$axios.post(url, { message })
  },
  sendSms ({ dispatch }, { itemId, mobile, message }) {
    return dispatch(
      'repos/call',
      {
        repo: 'customers.sms',
        method: 'store',
        parentId: itemId,
        payload: {
          mobile,
          message
        }
      },
      { root: true }
    )
  },
  async refreshSms ({ dispatch }, { itemId }) {
    const url = prepareUrl(uri.customers.refreshSms, { userId: itemId })
    const response = await this.$axios.post(url, {})
    if (response && response.data && response.status === 200) {
      const data = response.data
      if (data?.batch_id) {
        localStorage.setItem(`CustomerEditForm.${itemId}.smsBatch`, data.batch_id)
        dispatch('batchSms', { itemId, batchId: data.batch_id })
      }
    }
  },
  async batchSms ({ commit, dispatch, state }, { itemId }) {
    const batchId = localStorage.getItem(`CustomerEditForm.${itemId}.smsBatch`)
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0 && batchId) {
      const item = state.forms[fetchedIdx]
      const url = prepareUrl(uri.customers.batchSms, { userId: itemId, batchId })
      await dispatch('setLoadingEnable', false, { root: true })
      const response = await this.$axios.get(url)
      await dispatch('setLoadingEnable', true, { root: true })
      if (response && response.data && response.status === 200) {
        const data = response.data
        commit('setBatchInfo', { itemId, data })
        if (data.progress === 100) {
          localStorage.removeItem(`CustomerEditForm.${itemId}.smsBatch`)
          commit('flushBatchInfo', { itemId })
        } else if (!item.smsBatch.timer) {
          const timer = setInterval(() => {
            dispatch('batchSms', { itemId, batchId: data.batch_id })
          }, 1000)
          commit('setBatchInfo', { itemId, timer })
        }
      }
    }
  }
}
export const mutations = {
  updateField,
  ...gfMutations,
  fill (state, { id, data }) {
    const form = data?.form
    const formData = data?.formData
    const info = data?.info
    const affiliate = data?.affiliate
    const relatedProfiles = data?.related_profiles
    const logs = data?.logs
    const utmTrack = data?.utm_track
    const createdAt = data?.created_at
    const deletedAt = data?.deleted_at
    const validatedAt = data?.validated_at
    const lastActivity = data?.last_activity
    const passwordLocked = data?.password_locked
    const staffTempPass = data?.staff_temp_pass
    const userId = data?.id
    const platform = data?.platform
    const tecMobile = data?.tec_mobile
    const media = data?.media
    const supportChatId = data?.support_talk_id
    const dirtyJson = localStorage.getItem(`CustomerEditForm.${id}`)
    const dirty = dirtyJson ? JSON.parse(dirtyJson) : {}
    const dirtyKey = Object.keys(dirty).length ? JSON.stringify(dirty).hashCode() : 0
    const fetchedIdx = state.forms.findIndex(i => i.id === id)
    const item = fetchedIdx >= 0
      ? state.forms[fetchedIdx]
      : {
          id,
          type: data?.type,
          dirty,
          dirtyKey,
          oldForm: getFormInitState(customerFormFields),
          form: getFormInitState(customerFormFields),
          formData,
          info,
          affiliate,
          relatedProfiles,
          logs,
          utmTrack,
          createdAt,
          deletedAt,
          validatedAt,
          lastActivity,
          passwordLocked,
          staffTempPass,
          userId,
          platform,
          tecMobile,
          media,
          supportChatId,
          notes: {
            public: (data?.notes && data.notes.public) || [],
            private: (data?.notes && data.notes.private) || []
          },
          key: 0,
          // Form components refresh keys
          keys: {
            CustomerEditForm: null,
            OptionsList: null,
            InfoList: null,
            UserTitle: null,
            UserStatus: null,
            UserPlatform: null,
            Description: null
          },
          isFetched: false,
          editColumnName: '',
          editColumnState: false,
          editColumnCursor: -1,
          permanentDeleteList: {},
          chartCredits: {},
          smsBatch: {
            timer: null,
            id: null,
            name: null,
            progress: null,
            failedJobs: null,
            pendingJobs: null,
            processedJobs: null,
            totalJobs: null
          }
        }
    // Fill form data
    item.form.privacy = parseInt(form?.privacy || 0, 10)
    item.form.level = (form && `${form.level}`) || ''
    item.form.doc_verified = (form && form.doc_verified) || false
    item.form.is_vip = (form && form.is_vip) || false
    item.form.gallery_verified = (form && form.gallery_verified) || false
    item.form.gallery_verified_by_admin = (form && form.gallery_verified_by_admin) || false
    item.form.city_changed_by_admin = (form && form.city_changed_by_admin) || false
    item.form.ranking = (form && form.ranking) || 0
    item.form.stars = (form && form.stars) || 0
    item.form.stars_fixed = (form && form.stars_fixed === 1) || false
    item.form.stars_changed = (form && form.stars_changed) || false
    item.form.hw_friend = (form && form.hw_friend) || false
    item.form.ranking_blocked_value = (form && form.ranking_blocked_value) || 0
    item.form.real_ranking = (form && form.real_ranking) || 0
    item.form.disable_purchases = !!(form && form.disable_purchases)
    item.form.isSilenced = !!(form && form.is_silenced)
    item.form.can_invite = !!(form && form.can_invite)
    item.form.whitelisted = !!(form && form.whitelisted)
    item.form.alert_media = !!(form && form.alert_media)
    item.form.block_chat_credits = !!(form && form.block_chat_credits)
    item.form.check_task_enabled = !!(form && form.check_task_enabled)
    item.form.check_task_duration = parseInt((form && form.check_task_duration))
    item.form.check_task_created_at = (form && form.check_task_created_at)
    item.form.block_chat_new = !!(form && form.block_chat_new)
    item.form.block_feedback = !!(form && form.block_feedback)
    item.form.email = (form && form.email) || ''
    item.form.mobile = (form && form.mobile) || ''
    item.form.telegram = (form && form.telegram) || ''
    item.form.sms = !!(form && form.sms)
    item.form.email_notification = !!(form && form.email_notification)
    item.form.city_id = (form && form.city_id) || ''
    item.form.bday = form && form.bday ? this.$dateFormat(form.bday, 'YYYY-MM-DD') : ''
    item.form.description = (form && form.description) || ''
    item.form.display_name = (form && form.display_name) || ''
    item.form.control_nickname = (form && form.control_nickname) || ''
    item.form.status = (form && form.status) || 0
    item.form.platform = (form && form.platform) || 0
    item.form.characterMsgCost = form?.characterMsgCost || 0
    item.form.prime_duration = form?.prime_duration || 0
    item.form.characterStopLoss = form?.characterStopLoss || 0
    item.form.characterCostModifier = (form && form.characterCostModifier) || 0
    item.form.characterHostessNationality = (form && form.characterHostessNationality) || []
    item.form.characterSpokenLanguages = (form && form.characterSpokenLanguages) || []
    item.form.characterHeight = (form && form.characterHeight) || ''
    item.form.characterSize = (form && form.characterSize) || ''
    item.form.characterShoesNumber = (form && form.characterShoesNumber) || ''
    item.form.characterServices = (form && form.characterServices) || []
    item.form.characterAvailableForTransfer = !!(form && form.characterAvailableForTransfer)
    item.form.characterFreeChat = !!(form && form.characterFreeChat)
    item.form.characterWing = !!(form && form.characterWing)
    item.form.characterDisableCostModifier = !!(form && form.characterDisableCostModifier)
    item.form.password = ''
    item.form.bonusCredits = 0
    item.form.cashout = 0
    item.form.addCredits = 0
    item.form.addBoostPackageId = 0
    // Copy form data
    Object.keys(item.form)
      .forEach((key) => {
        item.oldForm[key] = item.form[key]
      })
    // Update dirty data
    const dirtyFields = Object.keys(item.dirty)
    if (dirtyFields.length) {
      for (const dirtyField of dirtyFields) {
        item.form[dirtyField] = item.dirty[dirtyField]
      }
    }
    // Store or update data
    if (fetchedIdx >= 0) {
      item.info = info
      item.affiliate = affiliate
      item.relatedProfiles = relatedProfiles
      item.logs = logs
      item.utmTrack = utmTrack
      item.createdAt = createdAt
      item.deletedAt = deletedAt
      item.validatedAt = validatedAt
      item.lastActivity = lastActivity
      item.passwordLocked = passwordLocked
      item.staffTempPass = staffTempPass
      item.userId = userId
      item.platform = platform
      item.tecMobile = tecMobile
      item.media = media
      item.supportChatId = supportChatId
      item.notes = {
        public: (data?.notes && data.notes.public) || [],
        private: (data?.notes && data.notes.private) || []
      }
      item.refresh = {
        existWaitingOrders: 0,
        newOrders: 0,
        noOrders: 0
      }
      state.forms[fetchedIdx] = item
    } else {
      state.forms.push(item)
    }
  },
  addNote (state, { itemId, type, note }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      const notes = state.forms[fetchedIdx].notes[type]
      notes.push(note)
      state.forms[fetchedIdx].notes[type] = notes
    }
  },
  deleteNote (state, { itemId, type, noteId }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      let notes = state.forms[fetchedIdx].notes[type]
      notes = notes.filter((n, i) => n.id !== noteId)
      state.forms[fetchedIdx].notes[type] = notes
    }
  },
  lockPassword (state, { itemId, data }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      const item = state.forms[fetchedIdx]
      item.passwordLocked = true
      item.staffTempPass = data?.password
      state.forms[fetchedIdx] = item
    }
  },
  unlockPassword (state, { itemId }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      const item = state.forms[fetchedIdx]
      item.passwordLocked = false
      item.staffTempPass = ''
      state.forms[fetchedIdx] = item
    }
  },
  setPermanentDeleteList (state, { itemId, data }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      const item = state.forms[fetchedIdx]
      item.permanentDeleteList = data
      state.forms[fetchedIdx] = item
    }
  },
  setChartCredits (state, { itemId, data }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      const item = state.forms[fetchedIdx]
      item.chartCredits = data
      state.forms[fetchedIdx] = item
    }
  },
  setBatchInfo (state, { itemId, data, timer }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      const item = state.forms[fetchedIdx]
      if (timer) {
        item.smsBatch.timer = timer
      }
      if (data) {
        item.smsBatch.id = data.id
        item.smsBatch.name = data.name
        item.smsBatch.progress = data.progress
        item.smsBatch.failedJobs = data.failedJobs
        item.smsBatch.pendingJobs = data.pendingJobs
        item.smsBatch.processedJobs = data.processedJobs
        item.smsBatch.totalJobs = data.totalJobs
      }
      state.forms[fetchedIdx] = item
    }
  },
  flushBatchInfo (state, { itemId }) {
    const fetchedIdx = state.forms.findIndex(i => i.id === itemId)
    if (fetchedIdx >= 0) {
      const item = state.forms[fetchedIdx]
      item.smsBatch.timer = null
      item.smsBatch.id = null
      item.smsBatch.name = null
      item.smsBatch.progress = null
      item.smsBatch.failedJobs = null
      item.smsBatch.pendingJobs = null
      item.smsBatch.processedJobs = null
      item.smsBatch.totalJobs = null
    }
  }
}
export const getters = {
  getField,
  ...gfGetters
}
